<template>
	<div class="layout-fine">
		<Card class="layout-fine__content content">
			<template #content>
				<div class="content__header">
					<Button
						:label="title"
						icon="pi pi-arrow-left"
						class="content__header-route text-xl"
						text
						@click="returnToPage" />

					<div class="content__header-title">
						<Tag
							:value="statusName"
							class="text-xs"
							:severity="statusSeverity" />

						<h2 class="text-3xl">Постановление №{{ getLayoutDataAttributes.resolution_number }}</h2>
					</div>
				</div>

				<Divider />

				<div class="content__info">
					<div class="content__info-box">
						<div
							v-for="info in infoItems"
							:key="info.name"
							class="content__info-item">
							<p class="content__info-item-name">{{ info.name }}</p>

							<p class="content__info-item-desc">{{ info.value }}</p>
						</div>

						<ClientOnly>
							<div
								v-if="photosViolationAttached"
								class="content__info-item content__info-item--full">
								<p class="content__info-item-name">Фото фиксации нарушения</p>

								<Galleria
									:value="getPhotos"
									container-class="detail-galleria"
									:thumbnails-position="thumbnailsPosition"
									:vertical-thumbnail-view-port-height="'auto'">
									<template #item="{ item }">
										<img
											:src="item"
											alt="Фото нарушения" />
									</template>

									<template #thumbnail="{ item }">
										<img
											:src="item"
											alt="Фото нарушения" />
									</template>
								</Galleria>
							</div>
						</ClientOnly>
					</div>
				</div>

				<Divider />

				<div class="content__footer">
					<ApplicationContestingInfo
						v-if="getLayoutDataAttributes.complaint"
						:guid="getLayoutDataAttributes.complaint"
						:type="getLayoutType" />

					<Challenge
						v-else
						:challenge-link="'application-contesting'"
						:page-type="getLayoutType" />
				</div>
			</template>
		</Card>

		<Payment
			:id="`${detailId}`"
			:data="getLayoutDataAttributes"
			:type="getLayoutType" />
	</div>
</template>

<script setup lang="ts">
	import { PaymentStatus } from "@/assets/ts/models/payment/PaymentStatus";
	import { LayoutDetailWithPayment } from "@/assets/ts/models/detail-with-payment/LayoutDetailWithPayment";
	import { type ILayoutDetailWithPayment } from "@/assets/ts/interfaces/detail-with-payment/ILayoutDetailWithPayment";
	import { LayoutTypes } from "@/assets/ts/enums/detail-with-payment/LayoutTypes";
	import { Routes } from "@/assets/ts/enums/Routes";
	import { PageTitles } from "@/assets/ts/enums/PageTitles";
	import { useCheckingValue } from "@/composables/useCheckingValue";

	const {
		params: { id: detailId },
	} = useRoute();

	const props = defineProps<{ data: ILayoutDetailWithPayment }>();

	const { getLayoutDataAttributes, photosViolationAttached, getPhotos, getLayoutType } = new LayoutDetailWithPayment(props.data);

	const { statusName, statusSeverity } = new PaymentStatus(getLayoutDataAttributes.payment_status);

	const viewport = useViewport();

	const thumbnailsPosition = computed(() => (viewport.isLessThan("xxl") ? "bottom" : "right"));

	const returnToPage = () => {
		const router = useRouter();

		if (getLayoutType === LayoutTypes.Penalty) router.push(Routes.Penalties);
		else if (getLayoutType === LayoutTypes.TollRoads) router.push(Routes.TollRoads);
	};

	const layoutTitlesMap: Record<LayoutTypes, PageTitles> = {
		[LayoutTypes.Penalty]: PageTitles.Penalty,
		[LayoutTypes.TollRoads]: PageTitles.TollRoads,
	};

	const layoutDateMap: Record<LayoutTypes, string> = {
		[LayoutTypes.Penalty]: "штрафа",
		[LayoutTypes.TollRoads]: "проезда",
	};

	const title = computed(() => {
		const title = layoutTitlesMap[getLayoutType as LayoutTypes];

		return title || "Неизвестный тип";
	});

	const dateTitle = computed(() => {
		const text = layoutDateMap[getLayoutType as LayoutTypes];

		return text ? `Дата ${text}` : "Дата";
	});

	useHead({ title });

	const infoItems = computed(() => [
		{
			name: "Автомобиль",
			value: useCheckingValue(getLayoutDataAttributes.auto.model),
		},
		{
			name: dateTitle.value,
			value: useCheckingValue(getLayoutDataAttributes.date),
		},
		{
			name: "Источник постановления",
			value: useCheckingValue(getLayoutDataAttributes.source),
		},
		{
			name: "Срок оплаты",
			value: useCheckingValue(getLayoutDataAttributes.payment_term),
		},
	]);
</script>

<style scoped lang="scss">
	@import "@/assets/scss/layouts/fine/styles";
</style>
