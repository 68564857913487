import type { ILayoutDetailWithPayment } from "@/assets/ts/interfaces/detail-with-payment/ILayoutDetailWithPayment";

export class LayoutDetailWithPayment {
	constructor(public data: ILayoutDetailWithPayment) {}

	get getLayoutDataAttributes() {
		return this.data.attributes;
	}

	get getLayoutType() {
		return this.data.type;
	}

	get getPhotos() {
		return this.data.attributes.file_links;
	}

	get photosViolationAttached() {
		const photos = this.getPhotos;

		return !!(photos && Array.isArray(photos) && photos.length);
	}
}
